import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Paginate
  },
  data() {
    return {
      busy: false,
      textInput: '',
      apiStatus: 'ไม่พบข้อมูล',
      perPage: 6,
      sortBy: 'name',
      totalItem: 0,
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { 
          key: 'code', 
          label: 'รหัสลูกค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2 py-1.5',
          sortable: true 
        },
        { 
          key: 'name', 
          label: 'ชื่อลูกค้า',
          thClass: 'font-weight-bold text-xs-1/2', 
          tdClass: 'text-gray-400 td-top text-xs-1/2 py-1.5',
          sortable: true 
        },
        { 
          key: 'address', 
          label: 'ที่อยู่',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top w-xs max-w-xs text-xs-1/2 py-1.5' 
        },
        { 
          key: 'tel', 
          label: 'เบอร์โทรศัพท์', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-left text-xs-1/2 py-1.5'  
        },
        { 
          key: 'action', 
          label: '#', 
          sortable: true, 
          thClass: 'font-weight-bold text-right text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-right text-xs-1/2 py-1.5'  
        }
      ],
      items: [],
     }
  },
  watch: {
    show: function (newValue, oldValue) {
      if (newValue) {
        this.items = []
        this.onSearchHandler(this.currentPage) 
      }
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onSelected (item) {
      this.$emit('onCustomerCloseHandler', {
        status: true,
        data: item
      })
    },
    onClose () {
      this.$emit('onCustomerCloseHandler', {
        status: false
      })
    },
    async onSearchHandler (currentPage) {
      try {
        this.busy = true
        // this.items = []
        const page = currentPage ? currentPage : 1
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
  
        const url = `${Vue.prototype.$host}/api/v1/customer?page=${page}&perPage=8&keyword=${this.textInput}`
        const param = {
          keyword: this.keyword,
          url: url
        }
        const result = await this.$store.dispatch('Customers/search', param, { root: true })
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          if (result) {
            this.currentData = result.data;
            this.items = result.data.data
            this.totalItem = result.data.total
          }
        }, 300)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message)
      }
    }
  }
}
